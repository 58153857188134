import axios from 'axios';
import { wixAxiosInstanceConfig } from '@wix/wix-axios-config';
import Experiments from '@wix/wix-experiments';
import { reportSentryError } from '@wix/dealer-common/dist/src/services';
import {
  DealerDataApiParams,
  DealerOfferApiParams,
  DealerOfferResponse,
  ExperimentsBag,
  ReportOfferEventsParams,
} from '../types';
import { GetDealerDataResponse } from './common';
import { BannerData, Offer } from '@wix/dealer-common/dist/src/interfaces';
import { convertOfferToBannerData } from '@wix/dealer-common/dist/src/converters/toDomain';

export interface DealerDataApiResponse {
  dealerData: { [position: string]: BannerData[] };
  experiments: ExperimentsBag;
}

export interface GetNewDealerDataResponse {
  banners: GetDealerDataResponse[];
  experiments: ExperimentsBag;
}

interface ListOffersResponse {
  offers: Offer[];
}

interface ListOffersRequest {
  realEstateId: string;
  excludeOfferIds: string[];
  limit?: number;
  ['extensions.siteId']?: string;
}

export const GET_OFFERS_V2 =
  '/_api/dealer-offers-serving-service/v1/dealer/serving/offers';

export const REPORT_EVENT_URL = (offerId: string) =>
  `/_api/dealer-offer-events-service/v1/dealer-offer-events/${offerId}`;

type Response<T> = T extends DealerOfferResponse
  ? DealerOfferResponse
  : { data: T };

async function getBanners(params: DealerDataApiParams): Promise<any[]> {
  const listOffersRequest: ListOffersRequest = {
    realEstateId: params.bannerPositions[0],
    limit: params.limit,
    excludeOfferIds: params.excludeLightboxes,
  };

  if (!params.signedInstance && params.metasiteGuid) {
    listOffersRequest['extensions.siteId'] = params.metasiteGuid;
  }

  const banners: GetDealerDataResponse[] = await ServerApi.doGet<
    ListOffersResponse
  >(
    GET_OFFERS_V2,
    listOffersRequest,
    params.signedInstance ? { Authorization: params.signedInstance } : {},
  ).then(({ data }) => {
    if (!data || !data.offers || data.offers.length === 0) {
      return [];
    }

    return data.offers.map(o => {
      const bannerData = convertOfferToBannerData(o);
      return {
        asset: bannerData,
        ...bannerData,
      };
    });
  });

  return banners;
}

export const ServerApi = {
  getDealerData: async (
    params: DealerDataApiParams,
  ): Promise<GetDealerDataResponse> => {
    const newParams = { ...params, limit: 1 };
    const { experiments, banners } = await ServerApi.getDealerDataNew(
      newParams,
    );
    const bannerData = banners[0] || {};

    const finalBannerData = {
      ...bannerData,
      experiments,
    };

    if (!finalBannerData.asset) {
      throw new Error('empty-data-dealer-content');
    }

    return finalBannerData;
  },
  getDealerDataNew: async (
    params: DealerDataApiParams,
  ): Promise<GetNewDealerDataResponse> => {
    const experimentsLoader = new Experiments();
    // tslint:disable-next-line:no-floating-promises
    experimentsLoader.load('dealer-client');

    // tslint:disable-next-line:no-floating-promises
    const experimentsPromise = experimentsLoader
      .ready()
      .then(() => {
        return experimentsLoader.all();
      })
      .catch(() => ({}));
    const [banners, experiments] = await Promise.all([
      getBanners(params),
      experimentsPromise,
    ]);
    const response: GetNewDealerDataResponse = {
      experiments,
      banners,
    } as GetNewDealerDataResponse;
    return response;
  },
  getOffersData: (
    { excludeAssetIds = [], realEstateId }: DealerOfferApiParams,
    headers: { [key: string]: string },
  ) => {
    const req: ListOffersRequest = {
      excludeOfferIds: excludeAssetIds,
      realEstateId,
    };

    return ServerApi.doGet<ListOffersResponse>(GET_OFFERS_V2, req, headers);
  },
  reportOfferEvent(params: ReportOfferEventsParams) {
    const { request, signedInstance } = params;

    if (!request.event.offerId) {
      return Promise.resolve({});
    }

    const headers = signedInstance ? { Authorization: signedInstance } : {};

    return ServerApi.doPost<DealerOfferResponse>(
      REPORT_EVENT_URL(request.event.offerId),
      request,
      headers,
    ).catch(e => {
      reportSentryError(e, {
        realEstateId: request.event.realEstateId,
        eventType: request.event.type,
        offerId: request.event.offerId,
        dealerApiType: 'events',
        reportingClass: 'ServerApi.reportOfferEvent',
      });
    });
  },

  doPost<TResponse = any, TParams = any>(
    url: string,
    params: TParams,
    headersParams?: { [key: string]: string },
  ): Promise<Response<TResponse>> {
    const instance = wixAxiosInstanceConfig(axios, {
      baseURL: '/',
    });
    return instance.post(url, params, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        ...headersParams,
      },
    });
  },
  doGet<TResponse = any, TParams = any>(
    url: string,
    params: TParams,
    headersParams?: { [key: string]: string },
  ): Promise<Response<TResponse>> {
    const instance = wixAxiosInstanceConfig(axios, {
      baseURL: '/',
    });
    return instance.get(url, {
      params,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        ...headersParams,
      },
    });
  },
};
