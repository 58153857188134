import { reportSentryError } from '@wix/dealer-common/dist/src/services/SentryReporter/SentryReporter';
import {
  LoadAssetParams,
  LoadAssetResponse,
  LoadAssetsOptions,
  LoadOfferResponse,
} from '../types';
import { DealerAssetCache } from './DealerAssetCache';
import { ServerApi } from '../ServerApi';

export const FedopsInteractions = {
  LOAD_ASSET: 'loadAsset',
};

export const calcBannerKey = (position: string, metasiteGuid: string) =>
  `${position}#${metasiteGuid}`;

export const shouldStartInteraction = (
  fedopsLogger: any,
  bannerKey: string,
  ignoreCache: boolean,
) => {
  if (!fedopsLogger) {
    return false;
  }
  return !DealerAssetCache.hasAsset(bannerKey) || ignoreCache;
};

export const DealerApi = {
  loadAsset(
    { position, metasiteGuid, signedInstance, fedopsLogger }: LoadAssetParams,
    options: LoadAssetsOptions,
  ): Promise<LoadAssetResponse> {
    return new Promise(resolve => {
      const { ignoreCache } = options;
      const bannerKey = calcBannerKey(position, metasiteGuid);
      if (shouldStartInteraction(fedopsLogger, bannerKey, ignoreCache)) {
        fedopsLogger.interactionStarted(FedopsInteractions.LOAD_ASSET);
      }
      const dealerResponse = DealerApi.getBannerData(
        position,
        metasiteGuid,
        bannerKey,
        fedopsLogger,
        ignoreCache,
        signedInstance,
      );

      DealerAssetCache.setAsset(bannerKey, dealerResponse);
      dealerResponse
        .then(({ asset, experiments }) => {
          return resolve({
            assetExist: () => true,
            bannerData: asset,
            isEmpty: false,
            experiments,
          });
        })
        .catch(e => {
          const isEmpty = e.message === 'empty-data-dealer-content';
          let error: Error;
          if (!isEmpty) {
            reportSentryError(e, {
              realEstateId: position,
              msId: metasiteGuid,
              dealerApiType: 'listOffers',
              reportingClass: 'DealerApi.loadAsset',
            });
            error = e;
          }
          resolve({
            assetExist: () => false,
            isEmpty,
            experiments: null,
            error,
          });
        });
    });
  },

  async loadOffers<T>(
    location: string,
    instance: string,
    reporter: any,
  ): Promise<LoadOfferResponse<T>> {
    try {
      const params = { realEstateId: location };
      const header = { Authorization: instance };
      const { data } = await ServerApi.getOffersData(params, header);

      return {
        offers: data.offers.map(offer => ({
          id: offer.offerGuid,
          payload: JSON.parse(offer.asset.payloadJson),
          name: offer.offerName,
        })),
        hasError: false,
        error: null,
      };
    } catch (err) {
      reportSentryError(err, {
        realEstateId: location,
        reportingClass: 'DealerApi.loadOffers',
      });
      reporter.captureException(err, {
        extra: { realEstateId: location, instance },
      });
      return { offers: [], hasError: true, error: err };
    }
  },
  getBannerData: (
    position: string,
    metasiteGuid: string,
    bannerKey: string,
    fedopsLogger: any,
    ignoreCache: boolean = false,
    signedInstance?: string,
  ) => {
    return DealerAssetCache.hasAsset(bannerKey) && !ignoreCache
      ? DealerAssetCache.getAsset(bannerKey)
      : ServerApi.getDealerData({
          metasiteGuid,
          bannerPositions: [position],
          signedInstance,
        }).then(response => {
          if (fedopsLogger) {
            fedopsLogger.interactionEnded(FedopsInteractions.LOAD_ASSET);
          }
          return response;
        });
  },
};
